<template>
    <modal ref="duplicarProducto" v-loading="loading_general" titulo="Editar producto" tamano="modal-lg" :cargando="cargando" @guardar="agregar_producto">
        <div class="px-4">
            <ValidationObserver ref="form1">
                <div class="row mx-3 pl-3">
                    <slim-cropper ref="cropImagen" :options="slimOptions" class="border br-10" style="height:160px;width:160px;background:#F8F9FF;">
                        <div slot="label" class="">
                            <!-- <img src="/img/modales/camera.svg" alt="" /> -->
                            <i class="icon-star" />
                        </div>
                    </slim-cropper>
                    <div class="col pr-0">
                        <div class="row mx-0 my-3">
                            <div class="col">
                                <ValidationProvider v-slot="{errors}" rules="required" name="nombre">
                                    <label class="ml-2">Nombre</label>
                                    <el-input v-model="model.nombre" size="small" maxlength="60" show-word-limit />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-0 my-3">
                            <div class="col">
                                <ValidationProvider v-slot="{errors}" rules="required" name="precio">
                                    <label class="ml-2">Precio</label>
                                    <money v-model="model.precio" class="input-money" v-bind="money" />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="" name="descripción">
                            <label>Descripción</label>
                            <el-input v-model="model.descripcion" type="textarea" :rows="3" maxlength="250" show-word-limit />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="required" name="unidad medida">
                            <label>Unidad de medida de venta</label>
                            <el-select v-model="model.id_unidad" size="small" class="w-100">
                                <el-option
                                v-for="item in unidades"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="required" name="vender por">
                            <label>Vender por</label>
                            <el-select v-model="model.cantidad_tipo" size="small" class="w-100" @change="cambio_tipo">
                                <el-option
                                label="Unidad"
                                :value="1"
                                />
                                <el-option
                                label="Fracción"
                                :value="2"
                                />
                            </el-select>
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" :rules="{required:true, decimal:model.cantidad_tipo == 1 ? 0 : 2}" name="cantidad mínima">
                            <label>Cantidad mínima de venta</label>
                            <el-input v-model="model.cantidad_minima" size="small" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="required" name="presentación">
                            <label class="">Presentación</label>
                            <el-input v-model="model.presentacion" size="small" maxlength="12" show-word-limit />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col">
                        <label>Categorías</label>
                        <el-select v-model="value_categoria" filterable size="small" class="w-100" @change="agregar_categoria">
                            <el-option
                            v-for="(item) in categorias"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            :disabled="item.disabled"
                            />
                        </el-select>
                        <div class="row mx-0">
                            <div v-for="(c,index) in categorias_aplicadas" :key="`key1${index}`" class="br-20 f-12 bg-gr-red pl-2 text-white d-middle m-2">
                                {{ c.nombre }}
                                <i class="icon-cancel f-20 cr-pointer ml-2" @click="remover_categoria(c.id)" />
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <label>Etiquetas</label>
                        <el-select v-model="value_etiqueta" filterable size="small" class="w-100" @change="agregar_etiqueta">
                            <el-option
                            v-for="item in etiquetas"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            :disabled="item.disabled"
                            />
                        </el-select>
                        <div class="row mx-0">
                            <div v-for="(e,index2) in etiquetas_aplicadas" :key="`key2${index2}`" class="br-20 f-12 bg-gr-red pl-2 text-white d-middle m-2">
                                {{ e.nombre }}
                                <i class="icon-cancel f-20 cr-pointer ml-2" @click="remover_etiqueta(e.id)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col">
                        <el-switch v-model="model.estado" class="switch-red" inactive-text="Exhibir producto en la tienda" />
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import {Money} from 'v-money'
import Productos from "~/services/productos/productos"
export default {
    components: {Money},
    data(){
        return {
            money: {
                // decimal: ',',
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            model: {
                nombre: '',
                precio: '',
                descripcion: '',
                id_unidad: '',
                cantidad_tipo: '',
                cantidad_minima: '',
                presentacion: '',
                stock_uso: false,
                stock_minimo: '',
                stock_actual: '',
                estado: false,
            },
            slimOptions: {
                ratio: '1:1',
                label: 'Imagen Aqui',
                minSize: { width: 100, height: 100 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            loading_general:false,
            cargando:false,
            categorias: [],
            value_categoria:null,
            categorias_aplicadas:[],
            etiquetas: [],
            value_etiqueta:null,
            etiquetas_aplicadas:[],
            unidades: []
        }
    },
    methods: {
        async toggle(producto){
            await this.datos_basicos()
            this.value_categoria= null
            this.categorias_aplicadas= []
            this.value_etiqueta= null
            this.etiquetas_aplicadas= []
            this.load_data(producto)
            this.$refs.duplicarProducto.toggle()
        },
        load_data(producto){
            console.log(producto);
            this.model = {
                id:producto.id,
                nombre:producto.nombre,
                precio:producto.precio,
                descripcion:producto.descripcion,
                id_unidad:producto.id_unidad,
                cantidad_tipo:producto.cantidad_tipo,
                cantidad_minima:producto.cantidad_tipo===1 ? parseInt(producto.cantidad_minima) : parseFloat(producto.cantidad_minima),
                presentacion:producto.presentacion,
                id_tienda:producto.id_tienda,
                estado:Boolean(producto.estado),
                stock_uso: false
            }
            if(producto.foto){
                this.$refs.cropImagen.instanciaCrop.load(producto.imagen)
            }else{
                this.$refs.cropImagen.instanciaCrop.remove();
            }

            for (let c  of this.categorias){
                c.disabled = false
                for (let ca of producto.categorias){
                    if(c.id === ca.id_categoria){
                        this.categorias_aplicadas.push(c)
                        c.disabled = true
                    }
                }
            }
            for (let e  of this.etiquetas){
                e.disabled = false
                for (let ea of producto.etiquetas){
                    if(e.id === ea.id_etiqueta){
                        this.etiquetas_aplicadas.push(e)
                        e.disabled = true
                    }
                }
            }
        },
        async datos_basicos(){
            try {
                this.loading_general = true

                if(this.$usuario.rol_nombre === 'admin' || this.$usuario.rol_nombre === 'admin-vip'){
                    const {data} = await Productos.selects_admin()
                    this.categorias =  data.categorias
                    this.etiquetas =  data.etiquetas
                    this.unidades =  data.unidades
                    return
                }

                let params = {
                    vip:this.$usuario.proyecto.tipo,
                    id_tienda:this.$usuario.tienda.id
                }

                const {data} = await Productos.selects(params)
                this.categorias =  data.categorias
                this.etiquetas =  data.etiquetas
                this.unidades =  data.unidades

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading_general = false
            }
        },
        async agregar_producto(){
            try {

                const valid = await this.$refs.form1.validate()

                if(valid){
                    this.cargando = true
                    if(this.categorias_aplicadas.length === 0){
                        this.notificacion('Advertencia','Por favor seleccione almenos una categoria','warning')
                        return
                    }
                    this.model.categorias = this.categorias_aplicadas
                    this.model.etiquetas = this.etiquetas_aplicadas
                    this.model.imagen = this.$refs.cropImagen.instanciaCrop.dataBase64.output.image;
                    const {data} = await Productos.actualizar(this.model.id,this.model)
                    this.notificacion('Mensaje','Producto editado correctamente','success')
                    this.$refs.duplicarProducto.toggle()
                    this.$emit('editar')
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false
            }
        },
        cambio_tipo(){
            this.model.cantidad_minima = null
        },
        agregar_categoria(id){
            let cat = this.categorias.find(o=>o.id === id)
            cat.disabled = true
            this.categorias_aplicadas.push(cat)
            this.value_categoria = null
        },
        remover_categoria(id){
            this.categorias.find(o=>o.id === id).disabled = false
            let cat = this.categorias.find(o=>o.id === id)
            this.categorias_aplicadas.splice(this.categorias_aplicadas.indexOf(cat), 1)

        },
        agregar_etiqueta(id){
            let cat = this.etiquetas.find(o=>o.id === id)
            cat.disabled = true
            this.etiquetas_aplicadas.push(cat)
            this.value_etiqueta = null
        },
        remover_etiqueta(id){
            this.etiquetas.find(o=>o.id === id).disabled = false
            let cat = this.etiquetas.find(o=>o.id === id)
            this.etiquetas_aplicadas.splice(this.etiquetas_aplicadas.indexOf(cat), 1)

        }

    }
}
</script>
<style lang="css" scoped>

.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
